import React from 'react'
import './App.css'

import Advanced from './examples/Advanced'

function App () {

  return (
    <div className='app'>
      <Advanced />
    </div>
  )
}

export default App
