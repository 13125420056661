import React, { useState, useMemo, useRef } from 'react'
import TinderCard from 'react-tinder-card'
import { IoClose, IoRefresh, IoHeart } from 'react-icons/io5'

const db = [
  {
    name: 'Thank you',
    url: './img/thank-you.png'
  },
  {
    name: 'Dresscode',
    url: './img/dresscode.png'
  },
  {
    name: 'Timeline',
    url: './img/timeline.png'
  },
  {
    name: 'Save the date',
    url: './img/save-the-date.png'
  },
  {
    name: 'Start',
    url: './img/start.png'
  },
  {
    name: 'You are invited!',
    url: './img/you-are-invited.png'
  },
]

let messageTimeout;

function Advanced () {
  const [currentIndex, setCurrentIndex] = useState(db.length - 1)
  // const [lastDirection, setLastDirection] = useState()
  const [errorMessage, setErrorMessage] = useState('');
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex)

  const messages = [
    'Swipe left is not working.',
    'You are not allowed to swipe left.',
    'You better not swipe left.',
    'Don\'t ever try to swipe left.'
  ];

  const childRefs = useMemo(
    () =>
      Array(db.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  )

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val)
    currentIndexRef.current = val
  }

  const canGoBack = currentIndex < db.length - 1

  const canSwipe = currentIndex > 0

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    // setLastDirection(direction)
    updateCurrentIndex(index - 1)
  }

  const outOfFrame = (name, idx) => {
    // console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  }

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < db.length) {
      if( dir === 'left') {
        setErrorMessage(messages[Math.floor(Math.random()*messages.length)]);
        clearTimeout(messageTimeout);
        messageTimeout = setTimeout(() => setErrorMessage(''), 3000)
        return;
      }
      await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
    }
  }

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return
    const newIndex = currentIndex + 1
    updateCurrentIndex(newIndex)
    await childRefs[newIndex].current.restoreCard()
  }

  return (
    <div>
      <div className='cardContainer'>
        {db.map((character, index) => (
          <TinderCard
            ref={childRefs[index]}
            className='swipe'
            key={character.name}
            onSwipe={(dir) => swiped(dir, character.name, index)}
            onCardLeftScreen={() => outOfFrame(character.name, index)}
            preventSwipe={character.name === 'Thank you' ? ['up', 'down', 'left', 'right'] : ['up', 'down', 'left']}
          >
            <div
              style={{ backgroundImage: 'url(' + character.url + ')' }}
              className='card'
            >
              <h3>{character.name}</h3>
            </div>
          </TinderCard>
        ))}
      </div>
      <p>&nbsp; {errorMessage}</p>
      <div className='buttons'>
        <button style={{ backgroundColor: !canSwipe && '#999999' }} onClick={() => swipe('left')}><IoClose /></button>
        <button style={{ backgroundColor: !canGoBack && '#999999' }} onClick={() => goBack()}><IoRefresh /></button>
        <button style={{ backgroundColor: !canSwipe && '#999999' }} onClick={() => swipe('right')}><IoHeart /></button>
      </div>
    </div>
  )
}

export default Advanced
